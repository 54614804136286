import { Container, Flex } from '@energiebespaarders/symbols';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { readableColor } from 'polished';
import styled from 'styled-components';
import type { BoxProps } from '@energiebespaarders/symbols/components/Grid';
import type { containers } from '@energiebespaarders/symbols/styles/theme';
import type { HTMLProps } from 'react';

interface Props {
  size?: keyof typeof containers;
  backgroundColor?: string;
  containerCustomStyle?: any;
  removeHorizontalPadding?: boolean;
}

const StyledBlockWrapper = styled(Flex)<{ $bgColor?: string }>`
  background-color: ${x => themify(x.$bgColor) || 'transparent'};
  color: ${x =>
    readableColor(themify(x.$bgColor) || 'white', themify('grayBlack'), 'white', false)};
`;

/** Handles adding a background color and vertical padding to a block or section */
const BlockContainer: React.FC<
  Pick<HTMLProps<HTMLDivElement>, 'ref' | 'style'> & Props & BoxProps
> = ({
  children,
  size = 'lg',
  backgroundColor,
  containerCustomStyle,
  removeHorizontalPadding,
  py = [3, 4, 10],
  ...rest
}) => {
  return (
    <StyledBlockWrapper $bgColor={backgroundColor} width={1} py={py} {...rest}>
      <Container size={size} style={containerCustomStyle}>
        <Flex
          flexBasis="100%"
          justifyContent="center"
          px={removeHorizontalPadding ? 0 : [2, 3, 4, 2, 0]}
        >
          {children}
        </Flex>
      </Container>
    </StyledBlockWrapper>
  );
};

export default BlockContainer;

import { Box, Container, Flex } from '@energiebespaarders/symbols';
import { useIsTablet } from '@energiebespaarders/symbols/hooks';
import { mediaMin } from '@energiebespaarders/symbols/styles/breakpoints';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Image, StructuredText } from 'react-datocms';
import Scroll from 'react-scroll';
import styled, { css } from 'styled-components';
import theme from '~/styles/theme';
import type { fetchCategoryAndTopicBySlug_allFaqTopics } from '~/types/generated/cms/fetchCategoryAndTopicBySlug';
import type { getCategories_allFaqCategories } from '~/types/generated/cms/getCategories';
import type { getFaqItemsbyTopicId_allFaqItems } from '~/types/generated/cms/getFaqItemsbyTopicId';
import FaqGoogleStructuredData from 'src/datoCMS/FaqGoogleStructuredData';
import { BlockRenderer } from '../DatoCms/BlockRenderer';
import DatoMetaTags from '../DatoCms/DatoMetaTags';
import ImageCard from '../ImageCard';
import { constructUrlWithAnchor } from '../_cmsBlocks/AnchorLinks';
import FaqItem from './FaqItem';
import type { MyBlock } from 'src/datoCMS/types';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export interface TopicPageProps {
  type: 'topic';
  topic: fetchCategoryAndTopicBySlug_allFaqTopics;
  readonly faqItems: ReadonlyArray<getFaqItemsbyTopicId_allFaqItems>;
  readonly navbarCategories: ReadonlyArray<getCategories_allFaqCategories>;
}

const ScrollLink = Scroll.Link;
const Anchor = Scroll.Element;
const scroller = Scroll.scroller;

const StyledScrollLink = styled(ScrollLink)`
  color: black;
`;

export const StyledDatoImage = styled(Image)<{ $autoWidth?: boolean }>`
  ${x =>
    x.$autoWidth &&
    css`
      & img {
        width: auto !important;
      }
    `}
`;

interface DatoImageWrapperProps {
  $rounded?: boolean;
  $width: string;
  $height: string;
}

export const DatoImageWrapper = styled(Box)<DatoImageWrapperProps>`
  position: relative;
  flex: 1;

  & ${StyledDatoImage} {
    margin: auto;
    min-width: ${x => x.$width};
    max-height: ${x => x.$height};
    max-width: 100%;
    border: 0;
    border-radius: ${x => (x.$rounded ? '6px' : 'unset')};

    & img {
      border-radius: ${x => (x.$rounded ? '6px' : 'unset')};
      object-fit: contain;
    }
  }
`;

type Props = Omit<TopicPageProps, 'type' | 'navbarCategories'>;

const SideQuestionsContainer = styled(Flex)`
  top: 60px;
  position: sticky;
  flex-direction: column;

  ${mediaMin.md} {
    max-width: 300px;
  }

  ${mediaMin.xl} {
    max-width: 450px;
  }
`;
const Topic: React.FC<Props> = ({ topic, faqItems }) => {
  const [hasActiveQuestion, setHasActiveQuestion] = useState(false);
  const { title, subHeading, description, relatedArticles, category } = topic;

  const parentCategorySlug = category!.parentCategory?.id ? category!.parentCategory.slug : '';
  const canonicalUrl = `https://energiebespaarders.nl/faq/${
    parentCategorySlug ? `${parentCategorySlug}/` : ''
  }${topic.category!.slug}/${topic.slug}`;

  const router = useRouter();
  const currentPath = router.asPath;
  const isTablet = useIsTablet();

  useEffect(() => {
    const faqItemId = currentPath.split('#')[1];

    if (faqItemId && !hasActiveQuestion) {
      const faqItemQuestion = faqItems.find(i => i.id === faqItemId)?.question;

      scroller.scrollTo(faqItemQuestion!, {
        duration: 1500,
        delay: 500,
        smooth: true,
        offset: -70,
      });

      setHasActiveQuestion(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, hasActiveQuestion]);

  function handleSideItemClick(questionId: string) {
    setHasActiveQuestion(true);
    void router.push(`${constructUrlWithAnchor(currentPath, questionId)}`, undefined, {
      shallow: true,
    });
  }

  return (
    <>
      <FaqGoogleStructuredData faqItems={faqItems} />
      <DatoMetaTags metaTags={topic.seo} canonicalUrl={canonicalUrl} />
      <Flex width={1} style={{ position: 'relative' }} mx={[0, 0, 0, 0, -2]}>
        {!isTablet && (
          <Box width={[0, 0, 1 / 8]} px={2} style={{ minWidth: 300 }}>
            <SideQuestionsContainer ml={[2, 2, 4]} mt={10}>
              <Box py={2}>Inhoudsopgave</Box>
              {faqItems.map(i => (
                <StyledScrollLink
                  onClick={() => handleSideItemClick(i.id)}
                  key={i.id}
                  to={i.question!}
                  smooth
                  isDynamic
                  duration={500}
                  offset={-70}
                >
                  <Box style={{ fontWeight: 500 }} py={2} pr={2}>
                    {i.question}
                  </Box>
                </StyledScrollLink>
              ))}
            </SideQuestionsContainer>
          </Box>
        )}

        <Box width={[1, 1, 1, 6 / 8]} px={2}>
          <Container size="lg" style={{ maxWidth: 750 }} py={10}>
            <h1>{title}</h1>
            {subHeading ? (
              <h2
                style={{
                  fontSize: theme.type.scale[3],
                  fontWeight: 500,
                }}
              >
                {subHeading}
              </h2>
            ) : null}
            {topic.image ? (
              <Box mb={4}>
                <DatoImageWrapper $height={isTablet ? '250px' : '500px'}>
                  <StyledDatoImage data={topic.image!.responsiveImage!} />
                </DatoImageWrapper>
              </Box>
            ) : (
              <></>
            )}
            {description ? (
              <Box mb={4}>
                <StructuredText
                  data={description?.value as unknown as StructuredTextGraphQlResponse}
                  renderBlock={b => <BlockRenderer block={b.record as unknown as MyBlock} />}
                />
              </Box>
            ) : null}
            {faqItems.map(item => (
              <Anchor key={item.id} name={item.question!} id={item.question!}>
                <FaqItem key={item.id} item={item} />
              </Anchor>
            ))}
            {topic.relatedArticles?.length > 0 && (
              // TODO: refactor this into some sorta PopularArticles component
              <Box
                width={1}
                py={10}
                px={[2, 2, 2, 0]}
                style={{ background: theme.colors.transparent }}
              >
                <Container size="lg">
                  <Box display="flex" flexDirection="column">
                    <h2 style={{ textAlign: 'center' }}>Gerelateerde artikelen</h2>
                    <Flex flexWrap="wrap" mx={-2} py={10}>
                      {relatedArticles.map((t, i: number) => {
                        return (
                          <Box
                            style={{ cursor: 'pointer' }}
                            key={i}
                            px={2}
                            py={2}
                            display="flex"
                            width={[1, 1, 1 / 3, 1 / 3]}
                            onClick={() => router.push(`/faq/${t.category?.slug}/${t.slug}`)}
                          >
                            <ImageCard
                              responsiveImageData={t.image!.responsiveImage!}
                              alt="Gerelateerd artikel"
                              height="150px"
                              title={t.title!}
                              headingType="h3"
                              maxLinesOfContent={5}
                              content={
                                <StructuredText
                                  data={
                                    t.description?.value as unknown as StructuredTextGraphQlResponse
                                  }
                                  renderBlock={b => (
                                    <BlockRenderer block={b.record as unknown as MyBlock} />
                                  )}
                                />
                              }
                            />
                          </Box>
                        );
                      })}
                    </Flex>
                  </Box>
                </Container>
              </Box>
            )}
          </Container>
        </Box>
      </Flex>
    </>
  );
};

export default Topic;
